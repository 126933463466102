import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        exact: true,
        redirect: "/auth/login"
    },
    {
        path: "/messaging",
        name: "messaging",
    },
    {
        path: "/intelligence",
        name: "intelligence",
    },
    {
        path: "/contacts",
        name: "contacts",
    },
    {
        path: "/connect",
        name: "connect",
    },
    {
        path: "/auth/logout",
        name: "logout",
    },
    {
        path: "/settings/*",
        name: "settings",
    },
    {
        path: "/support",
        name: "support",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
