<template>
    <router-link v-if="mfe" :to="link" :class="currentPageActive()">
        <span class="say-simple-icon" :class="title.toLowerCase()"></span>
        <span class="title">{{ title }}</span>
    </router-link>
    <a v-else :href="link" :target="target">
        <span class="say-simple-icon" :class="title.toLowerCase()"></span>
        <span class="title">{{ title }}</span>
    </a>
</template>

<script>
export default {
    data() {
        return {
            hover: false,
        };
    },
    props: {
        title: String,
        iconClass: String,
        link: {
            type: String,
            default: "/",
        },
        mfe: Boolean,
        target: String,
    },
    methods: {
        currentPageActive() {
            // TODO
            return this.link === "/insights"
                ?"main-menu-button active"
                : "main-menu-button";
        },
    },
};
</script>

