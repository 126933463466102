import { AccessControl } from "accesscontrol";

export class SaySimpleAccess {
    static async init(axios, authorizer) {
        const userId = authorizer.getPropertyFromAccessToken("sub");
        const [user, permissions] = await Promise.all(
            [
                axios
                    .get(`${ process.env.VUE_APP_APIGATEWAY_ENDPOINT }/users/${ userId }`)
                    .then(response => response.data),
                axios
                    .get(`${ process.env.VUE_APP_APIGATEWAY_ENDPOINT }/auth/access-list`)
                    .then(response => response.data),
            ]
        );

        return new SaySimpleAccess(user, permissions);
    }

    constructor(user, permissions) {
        this.accessControl = new AccessControl();
        this.user          = user;
        this.role          = user.role || "guest";
        this.accessControl
            .setGrants(permissions)
            .lock()
        ;
    }

    can(resource, action) {
        const accessQuery = this.accessControl
            .can(this.role)
        ;

        if (
            typeof accessQuery[action] !== "function") {
            return false;
        }

        try {
            return accessQuery[action](resource).granted;
        } catch (e) {
            return false;
        }
    }

    /**
     *
     * @param accessList {{resource: string, action: string}[]|boolean}
     * @return boolean
     */
    validateAccess(accessList) {
        if (typeof accessList === "boolean") {
            return accessList;
        }

        if (!accessList) {
            return false;
        }

        // If you have permission for any criteria of a link, you have permission to see its link
        return accessList.some(
            access => this.can(access.resource, access.action)
        );
    }
}
