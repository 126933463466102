<template>
    <div id="mfe-main-menu-container" v-bind:class="{ 'minimized' : !menuIsMinimized }">

        <!-- Two logo containers so we can crossfade on minimize -->
        <div id="logo"></div>
        <div id="logo-minimized"></div>

        <ul id="main-menu">
            <li v-for="mainMenuItem in mainMenuItems">
                <AppMainMenuButton
                    v-if="mainMenuItem.isAccessible()"
                    :key="mainMenuItem.title"
                    :title="mainMenuItem.title"
                    :icon-class="mainMenuItem.icon"
                    :link="mainMenuItem.link"
                    :mfe="mainMenuItem.mfe"
                />
            </li>
        </ul>

        <ul id="agent-menu">
            <li>
                <div id="agent-status">
                    <span class="avatar">
                        <span class="status-indicator"></span>
                    </span>
                    <span class="name">{{ user.displayName || user.name }}</span>
                </div>
            </li>
            <li v-for="mainMenuItem in agentMenuItems">
                <AppMainMenuButton
                    v-if="mainMenuItem.isAccessible()"
                    :key="mainMenuItem.title"
                    :title="mainMenuItem.title"
                    :icon-class="mainMenuItem.icon"
                    :link="mainMenuItem.link"
                    :mfe="mainMenuItem.mfe"
                    :target="mainMenuItem.target"
                />
            </li>
        </ul>

        <button id="toggle-menu" @click="toggleMenu()">
            <span class="say-simple-icon arrow-right" v-bind:class="{ 'flip-horizontal' : !menuIsMinimized }"></span>
        </button>
    </div>
</template>

<script>
import AppMainMenuButton   from "@/components/MainMenuButton";
import * as SaySimpleAuth  from "@saysimple/auth-module";
import { SaySimpleClient } from "./plugins/saysimple-client";
import { SaySimpleAccess } from "./plugins/saysimple-access";

export default {
    name: "App",
    components: {
        AppMainMenuButton
    },
    data() {
        return {
            mainMenuItems: [
            ],
            agentMenuItems: [
                {
                    title: "Logout",
                    link: "/inbox/auth/logout",
                    isAccessible: () => true,
                },
                {
                    title: "Settings",
                    link: "/inbox/settings/user",
                    isAccessible: () => true,
                },
                {
                    title: "Status",
                    link: "https://status.saysimple.com/",
                    isAccessible: () => true,
                    target: "_blank",
                },
                {
                    title: "Support",
                    link: "/inbox/support",
                    isAccessible: () => true,
                },
            ],
            menuIsMinimized: true,
            authorizer: null,
            axios: null,
            access: null,
            user: {},
        };
    },
    async created() {
        await this.initAuth();
        this.axios  = SaySimpleClient.getAxios(this.authorizer);
        this.access = await SaySimpleAccess.init(this.axios, this.authorizer);
        this.user = this.access.user;

        this.mainMenuItems.push(
            {
                title: "Inbox",
                link: "/inbox/",
                isAccessible: () => true,
            },
            {
                title: "Insights",
                link: "/insights",
                isAccessible: () => {
                    // @TODO: Create a dedicated resource for insights in ACL
                    return this.access.validateAccess([{
                        resource: "channelSettings",
                        action  : "update",
                    }]);
                },
                mfe: true,
            },
            {
                title: "Contacts",
                link: "/inbox/contacts",
                isAccessible: () => true,
            },
            {
                title: "Connect",
                link: "/inbox/connect",
                isAccessible: () => this.access.validateAccess([{
                    resource: "pluginSettings",
                    action: "create",
                }]),
            },
            {
                title: "Secure Export",
                link: "/inbox/export",
                isAccessible: () => this.access.validateAccess([{
                    resource: "exports",
                    action: "create",
                }]),
            }
        );
    },
    methods: {
        // Intended async await, to try and keep code from auth-module work with out coding standards
        async initAuth() {
            /**
             * @type {SaySimpleAuth}
             */
            this.authorizer = await this.getAuthorizer();
        },
        async getAuthorizer() {
            // auth-module setup is bad, wont allow us to use promise.catch
            try {
                return SaySimpleAuth.getAuthorizer();
            } catch (e) {
                await SaySimpleAuth.init(
                    `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/authenticate`,
                    `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/refresh`,
                    `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/expire-token`,
                    SaySimpleAuth.getPersistentSession()
                );

                return SaySimpleAuth.getAuthorizer();
            }
        },
        toggleMenu() {
            this.menuIsMinimized = !this.menuIsMinimized;
        },

        async logout() {
            await SaySimpleAuth.logout();

            // eslint-disable-next-line
            window.location.pathname = "/";
        }
    },
};
</script>

// This will import the SCSS scoped to this component only
<style scoped src="@/assets/scss/main-menu.scss" lang="scss"></style>
